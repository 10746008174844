.box-grid {
  background-color: var(--greyColor);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 28px 40px 25px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  text-decoration: none;

  &::before {
     background: linear-gradient(
      90deg,
      rgba(157, 43, 230, 0.5) 20.56%,
      rgba(233, 46, 178, 0.5) 51.01%,
      rgba(255, 198, 52, 7.5) 122.45%
    );
    position: absolute;
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
    z-index: -1;
    content: "";
    border-radius: 10px;
    opacity: 0;
    transition: cubic-bezier(0.1, 0, 0.2, 1) 0.5s;
  }

  &:hover {
    box-shadow: 5px 5px 20px rgba(87, 10, 99, 0.15);
    &::before {
      opacity: 1;
      transition: cubic-bezier(0.1, 0, 0.2, 1) 0.5s;
    }
    img {
      opacity: 1;
      transition: cubic-bezier(0.1, 0, 0.2, 1) 0.6s;
    }
  }

  img {
    width: 130px;
    height: 130px;
    opacity: 0.5;
    transition: cubic-bezier(0.1, 0, 0.2, 1) 0.6s;
  }

  p {
    margin: 20px 0 0 0;
    color: var(--darkFont);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
  }
}
.disabled {
  background-color: rgb(222, 222, 222);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 28px 40px 25px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  text-decoration: none;

  .opacity {
    opacity: .25;
  }

  .not-opacity {
    display: none;
  }

  .out-of-stock {
    color: red;
    font-size: 13px;
  }

  &::before {
    background-image: linear-gradient(
      90deg,
      rgba(157, 43, 230, 0.5) 20.56%,
      rgba(233, 46, 178, 0.5) 51.01%,
      rgba(255, 198, 52, 7.5) 122.45%
    );
    position: absolute;
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
    z-index: -1;
    content: "";
    border-radius: 10px;
    opacity: 0;
    transition: cubic-bezier(0.1, 0, 0.2, 1) 0.5s;
  }

  &:hover {
    box-shadow: 5px 5px 20px rgba(87, 10, 99, 0.15);
    .opacity {
      display: none;
    }
    .not-opacity {
      display: inline;
    }
    &::before {
      opacity: .5;
      transition: cubic-bezier(0.1, 0, 0.2, 1) 0.5s;
    }
    img {
      opacity: .5;
      transition: cubic-bezier(0.1, 0, 0.2, 1) 0.6s;
    }
  }

  img {
    width: 130px;
    height: 130px;
    transition: cubic-bezier(0.1, 0, 0.2, 1) 0.6s;
  }

  p {
    margin: 20px 0 0 0;
    color: black;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
  }
}

@media (max-width: 800px) {
  .box-grid {
    padding: 10% 14%;
    text-align: center;
    p{
      font-size: 11px;
      line-height: 1;
    }


    img {
      width: 70%;
      height: auto;
    }
  }
  .disabled{
    padding: 0;
    padding: 10% 14%;
    text-align: center;
    p{
      font-size: 11px;
    }
    img{
      width: 70%;
      height: auto;
    }
  }
}
