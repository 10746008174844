.content-file {
  width: 100%;
  text-align: center;
  height: 74px;
  border-radius: 15px;
  background: white;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skip {
  margin: 11px 0 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
  span {
    display: block;
    font-weight: 600;
    margin-bottom: 2px ;
  }
}

.file-format-supported{
  margin: 20px 0 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  color: #000000;
  opacity: 0.7;
}

.size-supported{
  margin: 20px 0 0;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  color: #000000;
  opacity: 0.7;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile + label {
  color: rgba(137, 40, 233, 0.7);
  display: flex;
  font-style: normal;
  font-weight: 800;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  align-items: center;
  overflow: hidden;
  text-align: center;
  justify-content: center;
  background-color: #ffffffb3;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%238928E9B3' stroke-width='2' stroke-dasharray='9' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 15px;
}

.inputfile + label {
  cursor: pointer;
}
.inputfile:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}
.inputfile + label * {
  pointer-events: none;
}

.inputfile span {
  display: block;
}
