:root {
  --font-primary: "Libre Franklin", sans-serif;
  --font-secondary: "Dela Gothic One", cursive;
  --dark-color: #0d0224;
  --greyColor: #f7f7f7;
  --darkFont: #0a192a;
  --baseGradient: linear-gradient(100deg, #9d2be6 0%, #ea2fb2 47.92%, #ffcd39 100%);
  --bigGradient: linear-gradient(140deg, #05acdf 1%, #9d2be5 39%, #ea2fb2 58%, #ffc634 92%);
  --sliderGradient: linear-gradient(180deg, #b50ed3 5%, #f99967 56%);
  --green: #36f6d4;
  --lemon: #a9fa57;
  --yellow: #f9d519;
  --cbd: #ef9a1b;
  --purple: #9d2be5;
  --red: #eb3f35;
  --error: #7a6363;
  --background-personal-info: #F7F7F7;
}

body {
  margin: 0;
  font-family: var(--font-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  #root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }
}

.error-txt {
  font-size: 0.7rem;
  color: var(--error);
  text-align: center;
}

.error-txt-zip {
  font-size: 1rem;
  color: red;
  text-align: center;
}

.error-message {
  display: flex;
  justify-content: center;
  animation: 0.3s linear toTop;

  p {
    font-size: 13.5px;
    display: flex;
    align-items: center;
    color: var(--error);
    font-weight: 500;

    img {
      margin-right: 5px;
    }
  }
}

@keyframes toTop {
  from {
    opacity: 0.5;
    transform: translate(-50%, 10%);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.center-text-banner {
  text-align: center;
  width: 50%;
  h2 {
    margin: 0;
    color: white;
    font-family: var(--font-secondary);
    font-style: normal;
    font-weight: normal;
    font-size: 41px;
    line-height: 123.46%;
    text-align: center;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
  }
}
.content-loader {
  height: calc(100vh - 366px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-shipping {
  color: white;
  background: #9D2BE5;

  border-radius: 42px;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 48px;
  text-transform: uppercase;
  border: none;
  padding: 0px 70px;
  height: 48px;
  box-sizing: border-box;
  cursor: pointer;
  font-family: var(--font-primary);
  display: block;
  text-decoration: none;
  text-align: center;

  &:hover {
    box-shadow: 5px 5px 30px 0px rgba(87, 10, 99, 0.25);
  }
}

.btn-gral {
  color: white;
  background: var(--baseGradient);
  border-radius: 42px;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 48px;
  text-transform: uppercase;
  border: none;
  padding: 0px 70px;
  height: 48px;
  box-sizing: border-box;
  cursor: pointer;
  font-family: var(--font-primary);
  display: block;
  text-decoration: none;
  text-align: center;

  &:hover {
    box-shadow: 5px 5px 30px 0px rgba(87, 10, 99, 0.25);
  }
}

.btn-secondary {
  background: white;
  border: solid 2px var(--purple);
  color: var(--purple);
  opacity: 0.7;
}

.title-cat {
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: normal;
  font-size: 41px;
  line-height: 123.46%;
  text-transform: capitalize;
}

.down-text-cat {
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 1.4;
  text-align: center;
  margin: 0px auto 40px;
  width: 80%;
}

.box-selector {
  margin-bottom: 15px;
  h2 {
    font-family: var(--font-secondary);
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 20px;
    margin: 0 0 22px 0;
  }
}

    .button-submit-container {
      display: flex;
      justify-content: center;
    }


  .form-login-container{
    width: 65%;
  }


.checkcontainer {
  display: inline-block;
  position: relative;
  padding-left: 50px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 0.9rem;
  user-select: none;
  color: black;
  width: fit-content;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
  p {
    margin: 0;
    display: flex;
    justify-content: space-between;
    width: 245px;
    align-items: center;

    span{
      display: block;
    }

    label {
      .inputSelect {
        opacity: 1;
        position: relative;
        width: 70px;
        height: 35px;
        padding-left: 5px;
        font-family: var(--font-primary);
        margin-top: 5px;
      }
    }
  }

  input:checked ~ p {
    font-weight: 600;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 1rem;
    appearance: none;
    left: 0;
    top: 0;
  }

  input:checked ~ .checkmark {
    background-color: #8928e9;
  }
  input:checked ~ .checkmark:after {
    display: block;
    left: 31%;
    top: 50%;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 2.4px 2.4px 0;
    transform: rotate(45deg) translate(-50%, -50%);
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: rgba(192, 194, 197, 0.2);
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
}

.input-gral {
  background: #ffffff;
  border: 1px solid #848484;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
}

.select-gral {
  background: #ffffff;
  border: 1px solid #848484;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  padding: 0.5rem;
  font-family: var(--font-primary);
  option{
    font-family: var(--font-primary);
  }
}

.box-form {
  border: 1.5px solid;
  border-image-slice: 1;

  border-image-source:var(--baseGradient);
  background-color: white;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 10px;

  input {
    min-height: 40px;
    line-height: 40px;
    padding: 0 10px;
    border-radius: 10px;

    &:not(:last-child) {
      margin-bottom: 18px;
    }
  }
}

.totalpay-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin: 0;
  }
}

.title-box-form {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  margin: 0 0 10px 0;
  font-family: var(--font-secondary);
  color: #000000;
}

.content-library {
  display: flex;

  .filter-library-side {
    width: 300px;
    background-color: var(--greyColor);
    height: 80vh;
    position: sticky;
    top: 0;
    align-self: flex-start;
  }
}

@media (max-width: 1100px) {
  .title-cat {
    font-size: 30px;
    line-height: 123.46%;
    margin-top: 20px;
  }
  .content-library {
    flex-direction: column;
    padding-top: 70px;

    .filter-library-side {
      width: 100%;
      height: auto;
      position: initial;
    }
  }
}

@media (max-width: 992px) {
  .down-text-cat {
    font-size: 16px;
    line-height: 1.4;
    margin: 0px auto 36px;
    width: 92%;
  }
  .totalpay-box {
    margin-bottom: 1.3rem;
  }
  .box-form {
    padding: 20px;
  }
}
