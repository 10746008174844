.content-library {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .filter-library-side {
    width: 300px;
    background-color: var(--greyColor);
    height: 100vh;
    position: sticky;
    top: 0;
    align-self: flex-start;
    margin-left: 5rem;

    h3 {
      margin-top: 40px;
      text-align: center;
    }

    ul {
      list-style-type: none;
      padding: 3rem 0 0;
      margin: 0;

      li {
        a {
          text-decoration: none;
          padding: 0 0 0 50px;
          height: 46px;
          line-height: 46px;
          width: 100%;
          display: block;
          color: var(--darkFont);
          font-weight: 600;
          text-transform: uppercase;

          &.active {
            color: var(--purple);
          }
        }
      }
    }
  }

  .library-grid-content {
    padding: 4rem 10% 9rem;
    width: calc(100% - 300px);

    .grid-library {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
      grid-gap: 70px 40px;
    }
  }
}


@media (max-width: 1280px) {
  .content-library .library-grid-content {
    padding: 4rem 5% 9rem;

    .grid-library {
      grid-gap: 63px 30px;
    }
  }
}

@media (max-width: 1500px) {
  .content-library {
    .filter-library-side {
      h3 {
        margin-top: 7rem;
      }
    }
  }
}

@media (max-width: 1100px) {
  .content-library {
    flex-direction: column;
    padding-top: 70px;

    .filter-library-side {
      width: 100%;
      height: auto;
      position: initial;
    }

    .library-grid-content {
      width: 100%;
    }
  }

  .content-library {
    .filter-library-side {
      margin-left: 0;
    }
  }
}

@media (max-width: 1370px) and (min-width: 1300px) {
  .content-library {
    .filter-library-side {
      margin-left: 12rem !important;
    }
  }
}