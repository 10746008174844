.relative {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }
  .icons-back {
    position: absolute;
    z-index: -1;
    top: 70%;
    opacity: 0.4;
    background: url(/assets/img/guarda.png) no-repeat top;
    left: 0;
    height: 126px;
    width: 100%;
    background-size: contain;
    background-repeat: repeat-x;
  }

  .content-element {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin: 2rem auto;
    position: relative;
    padding: 0 150px;
    min-height: 90vh;

    .select-side {
      width: 400px;
    }

    .text-element {
      width: 65%;
      text-align: left;
      .down-text-cat{
        img{
          width: 300px;
          object-fit: cover;
        }
      }
      h5 {
        text-align: left;
        margin: 9% 0 4% 0;
      }

      p {
        text-align: left;
        margin: 0;
      }
    }
  }

  @media (max-width: 1280px) {
    .content-element {
      padding: 0 100px;
    }
  }

  @media (max-width: 1100px) {
    .content-element {
      padding: 70px 0 0;
      flex-direction: column-reverse;
      width: 100%;
      margin: 0 auto 4.5rem;
      box-sizing: border-box;

      .select-side {
        width: 90%;
        margin: 2rem auto;
      }
      .text-element {
        width: 100%;
        text-align: center;

        h5 {
          text-align: center;
        }

        p {
          text-align: center;
          margin: 10px auto;
        }
      }
    }
  }
