.span-discount {
    span {
        color: rgb(0, 177, 0);
        font-size: .65rem;
        margin-left: 1rem;
    }
}

.container-discount {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


@media (max-width: 1100px) {
    .container-discount {
        flex-direction: column;
        align-items: flex-end;
    }
  }
  