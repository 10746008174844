.promo-banner-home {
  background-color: #0D0224;
  padding: 65px 7rem;
  text-align: left;
  margin: 5rem 0px 7rem;
  display: flex;
  flex-direction: column;
  background-size: cover;
  position: relative;

  .text-banner-home {
    width: 64%;
    h3 {
      font-weight: normal;
      font-size: 41px;
      line-height: 123.46%;
      font-family: var(--font-secondary);
      color: white;
      margin: 0 0 2.6rem;
      text-align: left;
    }

    button {
      width: fit-content;
      height: 4rem;
    }
  }

  .absolute-content-img {
    position: absolute;
    right: 9%;
    top: 16px;
    width: 340px;
    height: 347px;
  }

  .content-img-hover {
    margin-top: -3.4rem;
    position: relative;
    width: 340px;
    height: 347px;
    z-index: 10;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
    -webkit-perspective: 220px;
    perspective: 220px;

    img {
      width: 400px;
      height: 407px;
      transition: all 0.2s linear;
    }

    .tl,
    .tr,
    .bl,
    .br {
      width: 50%;
      height: 50%;
      position: absolute;
      z-index: 1;
      cursor: pointer;
      transition: all 0.2s linear;
    }

    .tl {
      top: 0;
      left: 0;

      &:hover ~ img {
        -webkit-transform: rotateX(-1deg) rotateY(2deg);
        transform: rotateX(-1deg) rotateY(2deg);
        transition: all 0.2s linear;
      }
    }

    .tr {
      top: 0;
      right: 0;
      &:hover ~ img {
        -webkit-transform: rotateX(-1deg) rotateY(-2deg);
        transform: rotateX(-1deg) rotateY(-2deg);
        transition: all 0.2s linear;
      }
    }

    .bl {
      bottom: 0;
      left: 0;

      &:hover ~ img {
        -webkit-transform: rotateX(1deg) rotateY(2deg);
        transform: rotateX(1deg) rotateY(2deg);
        transition: all 0.2s linear;
      }
    }

    .br {
      bottom: 0;
      right: 0;
    }

    &:hover {
      -webkit-transform: scale(1.02, 1.02);
      transform: scale(1.02, 1.02);
      transition: all 0.2s linear;
    }
  }
}

@media (max-width: 992px) {

  .promo-banner-home {
    margin: 2rem auto;
    width: 100%;
    padding: 50px 1rem;

    img {
      width: 28%;
      right: 36px;
      top: 16px;
    }

    .text-banner-home {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      h3 {
        font-size: 23px;
      }
      button {
        height: 3rem;
      }
    }
    .content-img-hover {
      display: none;

    }
  }
}

@media (max-width:600px) {
  .promo-banner-home{
    img{
      display: none;
    }
    .text-banner-home{
      width: 100%;
      text-align: center;

      button{
        margin: 2rem auto 0;
      }

      h3{
        text-align: center;
        width: 80%;
        margin: 0 auto;
      }
    }
  }
}
