.sticker-side-element {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 4rem 10% 9rem 4%;

  img {
    width: 36%;
  }

  .text-element-sticker {
    width: 53%;

    h4 {
      font-family: var(--font-secondary);
      font-style: normal;
      font-weight: normal;
      font-size: 2em;
      line-height: 46px;
    }
    p {
      font-family: var(--font-primary);
      font-style: normal;
      font-weight: normal;
      font-size: 1em;
      line-height: 1.8;
    }
    .box-price {
      display: flex;
      margin-bottom: 1em;
      border-bottom: 0.5px solid #848484;
      border-top: 0.5px solid #848484;
      h2 {
        font-family: var(--font-primary);
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 30px;
      }
    }
    .input-library {
      padding: 30px 0;
      label {
        color: var(--dark-color);
        display: block;
        font-style: normal;
        font-weight: 800;
        font-size: 12px;
        line-height: 30px;
        text-transform: uppercase;
      }
    }
    .custom-sizes{
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .input-library{
        padding-bottom: 0;
      }

      span{
        font-weight: 500;
        line-height: 2;
        font-size: 17px;
        display: flex;
        align-content: center;
        justify-content: space-evenly;
        align-items: stretch;
        width: 3em;
      }
    }
  }
}

@media (max-width: 900px) {
  
  .sticker-side-element .text-element-sticker {
    max-width: 100%;
    width: 100%;
  }

  .sticker-side-element{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
      
    img {
      width: 36%;
    }
  
  }

  .content-library .filter-library-side ul li a{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 0 0;
  }

  .sticker-side-element .box-price{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0 0 0 0;
  }

  .custom-sizes{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .input-library{
      padding-bottom: 0;
    }

    span {
      display: block;
    }
  }

}