.react-pdf__Page__textContent,
.react-pdf__Page__annotations {
  display: none;
}
.react-pdf__Page__canvas {
  width: 150px !important;
  height: 150px !important;
  object-fit: contain;
  background-color: none !important;
}


.box-shop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  box-sizing: border-box;

  &:not(:last-child) {
    border-bottom: 1px solid #c4c4c4;
  }

  .fields {
    width: 103px;
    text-align: center;

    span {
      display: none;
    }

    &:first-child {
      width: 35%;
      text-align: left;
    }
  }

  .description-field {
    display: flex;
    align-items: center;
    img, iframe {
      width: 150px;
      height: 150px;
      object-fit: contain;
      transition: transform 0.3s ease-in-out;
    }
    img:hover, iframe:hover {
      transform: scale(1.4);
    }
    iframe{
      height: 82px;
     border-color: transparent;
    }
    .text-cart {
      width: 20%;
      margin-left: 5%;

      h5 {
        margin: 0 0 5px 0;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 19px;
        color: #000000;
      }
      span {
        font-size: 12px;
        display: block;
        font-style: normal;
        font-weight: normal;
      }
    }
  }

  .quantity-field {
    input {
      width: 65px;
      height: 44px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      text-align: center;

      &::placeholder {
        color: grey;
      }

      &:-webkit-inner-spin-button {
        display: none;
      }
    }
  }
  .price-field {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }
  .total-field {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }
  .delete-field button {
    background: none;
    border: none;
  }
}

.price-without-promo {
  text-decoration: line-through;
  color: #d9534f;
  margin-right: 8px;
  font-size: 13px;
}

.box-error {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
    color: red;
}

@media (max-width: 800px) {
  .box-shop {
    flex-wrap: wrap;
    position: relative;

    .fields {
      width: 100%;
      text-align: left;
      margin-bottom: 15px;

      &:first-child {
        width: 100%;
      }

      span {
        display: inline-block;
        margin-right: 11px;
        font-size: 13px;
        text-transform: uppercase;
        font-style: normal;
        font-weight: 500;
        color: #000000;
        opacity: 0.7;
        width: 103px;
        white-space: nowrap;
      }
    }
    .quantity-field {
      display: flex;
      justify-content: space-between;
      align-items: center;

      input {
        width: 60px;
        height: 38px;
      }
    }
    .total-field {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .price-field {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .delete-field {
      position: absolute;
      top: 20px;
      right: 0;
      width: auto;
      margin: 0;
    }
  }
}
