.content-items-library-home {
  display: flex;
  justify-content: space-between;
  margin: 1rem auto;
  flex-wrap: wrap;

  .box-item-library {
    width: 23%;
  }
}


.button-library-item {
  margin: 5rem auto 6rem;
}

@media (max-width: 1100px) {
  .items-library-texts{
    width: 90%;
    justify-content: center;
    padding-left: 0.4rem;
    h4 {
      text-align: center;
      font-size: 22px;
    }
    h3{
      text-align: center;
    }
  }
  .content-items-library-home {
    width: 90%;
    margin: 3rem auto;

    h4 {
      font-family: var(--font-secondary);
      margin: 0 0 30px 0;
      font-weight: 400;
      font-size: 20px;
      color: var(--darkFont);
      line-height: 1.2;
    }

    .box-item-library {
      width: 47%;
      margin-bottom: 4rem;
    }
  }
  .button-library-item {
    margin: 0rem auto 6rem;
  }
}

@media (max-width: 800px) {
  .items-library-texts{
    width: 95%;
    padding-left: 0rem;
    h4{
      text-align: center;
      font-size: 22px;
    }
    h3{
      text-align: center;
      font-size: 16px !important;
    }
  }
  .content-items-library-home {
    .box-item-library {
      height: auto;
      padding-top: 1rem;
      img {
        width: 70%;
      }
      p {
        font-size: 14px;
        line-height: 1.2;
        margin-bottom: 2rem;
      }
      button {
        font-size: 11px;
        height: 41px;
        line-height: 41px;
      }
    }
  }
}

@media (max-width: 600px) {
  .content-items-library-home {
    margin: 0;
    width: auto;

    .box-item-library {
      width: 48%;
      margin-bottom: 3rem;
    }
  }
}

