.filter-library {
  list-style-type: none;
  padding: 3rem 0 0;
  margin: 0;
  li {
    button {
      background: none;
      border: none;
      font: inherit;
      cursor: pointer;
      height: 46px;
      width: 100%;
      color: var(--darkFont);
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .active {
    color: white;
    background: var(--bigGradient);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    
  }
}
