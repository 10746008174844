.contact-page {
  display: flex;
  height: 100vh;

.contact-box{
  width: 60%;
  .send-btn{
    margin-top: 7.5rem ;
  }
}

  .content-side {
    align-items: center;
    justify-content: space-between;
    margin: 14% auto;

.contact-box {

  &.error {
    span {
      position: relative;

      &::after {
        content: '*';
        font-weight: bold;
        color: var(--error);
        margin-left: 2px;
        line-height: 5px;
      }
    }

    input {
      border-color: var(--error);
    }
  }

  .brand {
    width: 100%;
    height: 1.7rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 2rem;

    img {
      height: 100%;
    }
  }
}

.contact-cont{
  margin-top: 18rem;
  margin-right:10rem;


  p{

    font-size:14px;
    font-weight: 400;
    line-height: 5px;

  }

  h4{
    font-family: var(--font-secondary);
    font-style: normal;
    font-weight: 400;
    font-size: 1.7rem;
    line-height: 8px;
    margin-bottom: 2rem;
  }
}

  .link{
    color:blue
  }
  }

  textarea{
    height: 5rem;
  }
  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  }

  input[type=number] {
      -moz-appearance:textfield;
  }
  input, select, textarea {
    border: 1px solid rgb(187, 187, 187);
    border-radius: 5px;
    padding-top: .7rem 0.5rem;
    padding-right: 2.5rem;
    width: 100%;
    background-color: transparent;
    font-family: var(--font);
    resize: vertical;
    min-height: 2rem;
    margin-bottom: 1.5rem;
    &::placeholder{
      font-family: var(--font);
      color: rgb(199, 199, 199);
    }
  }

  .line-form{
    input, textarea {
      padding: 1rem;
      border-radius: 14px;
    }
    .line-form-container{
      display: flex;
      flex-direction: row;
      justify-content: space-between;

  .line-phone-container{
    display: flex;
    flex-direction: column;
  }
  .phone-code-input{
    width: 100%;
  }
}
  }

  .line-forms {
    position: relative;
    bottom: 99px;
    left: 200px;

   padding-top: .1rem 2rem;

   width: 47%;
   background-color: transparent;
   font-family: var(--font);
   resize: vertical;
   min-height: 2rem;
   margin-bottom: 1.5rem;

 }

 .line-formss {

  padding-top: .1rem 2rem;

  width: 47%;
  background-color: transparent;
  font-family: var(--font);
  resize: vertical;
  min-height: 2rem;
  margin-bottom: 1.5rem;

 }

  .contact-side {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

.back{
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
}

.brand{
  color: black;
  height: 2.6rem;
  font-family: var(--font-secondary);
  font-size: 28px;
  margin-bottom: 2rem;
}

span{
  font-size: 12px;
  font-weight: bold;
}

.logo-vikings{
  position: absolute;
  width: 81px;
  height: 82px;
  right:640px;
  top:49px;
}

.forgot{
  text-align: end;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 3rem;
  text-decoration: underline
}

.text-gradient{
  font-size: 14px;
  text-align: center;
  position: relative;
  bottom: 100px;
}

.btn-gral {
  width: 100%;
  text-transform: uppercase;
  justify-content: center;
  position:relative;
  bottom:100px;
}
}

}

@media(max-width: 992px) {
    .contact-page{
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
  }

  .contact-page .gradient-side {

width: 100%;
  }

  .contact-page .contact-side {
    width: 100%;
    padding-top: 2rem;
  }

  .contact-page .contact-side input, select, textarea {

width: 100%;
  }

  .contact-page .contact-side .line-forms {
    position: relative;
    bottom: 99px;
    left: 173px;

  padding-top: .1rem 2rem;

  width: 50%;
  background-color: transparent;
  font-family: var(--font);
  resize: vertical;
  min-height: 2rem;
  margin-bottom: 1.5rem;

  }

  .contact-page .contact-side .line-formss {

  padding-top: .1rem 2rem;

  width: 50%;
  background-color: transparent;
  font-family: var(--font);
  resize: vertical;
  min-height: 2rem;
  margin-bottom: 1.5rem;

  }

}
