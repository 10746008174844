.resume-order {
  display: flex;
  justify-content: space-between;
  background: white;
  padding: 40px 9%;
  box-sizing: border-box;
  border-radius: 10px;
  align-items: center;
  margin-bottom: 50px;

  p {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #000000;

    span {
      color: var(--purple);
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      display: block;
    }
  }
}

@media (max-width: 800px) {
  .resume-order {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 33px 2rem;

    p {
      margin-bottom: 2vh;
    }
  }
}
