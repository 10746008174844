.span-discount {
 margin-right: -20px;
    span {
        color: rgb(0, 177, 0);
        font-size: 12px !important;
        margin-left: 1rem;
        margin-right: 20px;
    }
}

.span-discount.hidden {
  visibility: hidden;
}


.container-discount {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.price-without-promo {
  text-decoration: line-through;
  color: #d9534f;
  margin-right: 8px;
  font-size: 13px;
}

.no-discount {
  margin-left: -3rem;
}


@media (max-width: 1100px) {
    .container-discount {
        flex-direction: column;
        align-items: flex-end;
    }
  }
