.relative {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;

  .background{
    position:absolute;
    top:0;
    left: 0;
    right: 0;
    bottom:0;
    background-size: 8%;
    opacity: .05;
    background-image: url(../../assets/img/logo-vikings.png);
  }
}
.icons-back {
  position: absolute;
  z-index: -1;
  top: 70%;
  opacity: 0.4;
  background: url(/assets/img/guarda.png) no-repeat top;
  left: 0;
  height: 126px;
  width: 100%;
  background-size: contain;
  background-repeat: repeat-x;
}

.onlydesk{
  display: inherit;
}

.onlymob{
  display: none;
}

.content-element {
  display: flex;
  justify-content: space-between;
  background-repeat: repeat;
  background-size: 10%;
  box-sizing: border-box;
  margin: 2rem auto;
  position: relative;
  padding: 0 150px;
  min-height: 90vh;

  .select-side {
    width: 400px;
    padding-top:5rem;
  }

  .text-element {
    width: 60%;
    text-align: left;
    h5 {
      text-align: left;
      margin: 5% 0 5% 0;
      color:#8928e9;
    }

    p {
      text-align: left;
      margin: 0;
    }
  }
}

@media (max-width: 1280px) {
  .content-element {
    padding: 0 100px;
  }
}

@media (max-width: 1100px) {


  .galleryCont{
    gap: 1rem !important;
    flex-direction: row !important;
    margin-bottom: 1rem !important;
  }

.onlydesk{
  display: none;
}

.onlymob{
  display: inherit;
}
  .content-element {
    padding: 70px 0 0;
    flex-direction: column-reverse;
    width: 100%;
    margin: 0 auto 4.5rem;
    box-sizing: border-box;

    .select-side {
      width: 90%;
      padding-top: 0rem;
      margin: 2rem auto;
    }
    .text-element {
      width: 100%;
      text-align: center;

      h5 {
        text-align: center;
      }

      p {
        text-align: center;
        margin: 10px auto;
      }
    }
  }
}
