
.login-page {
  display: flex;
  height: 100vh;

  .gradient-side {
    background:  linear-gradient(
      140deg,
    #05acdf 1%,
    #9d2be5 39%,
    #ea2fb2 50%,
    #ffc634 92%
    );

    color: white;
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .login-box {
      &.error {
        span {
          position: relative;

          &::after {
            content: '*';
            font-weight: bold;
            color: var(--error);
            margin-left: 2px;
            line-height: 5px;
          }
        }

        input {
          border-color: var(--error);
        }
      }

      .brand {
        width: 100%;
        height: 1.7rem;
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;
        font-weight: 600;
        font-size: 2rem;

        img {
          height: 100%;
        }
      }

    }

    .login-cont{
      margin-top: 18rem;
      margin-right:10rem;

      p{
        font-size:18px;
        font-weight: 400;
        line-height: 5px;
      }

      h4{
        font-family: var(--font-secondary);
        font-style: normal;
        font-weight: 400;
        font-size: 3rem !important;
        line-height: 0px;
        margin-bottom:2rem;
      }
    }

      .link{
        color:blue
      }
  }
  input, select, textarea {
    border: 1px solid rgb(187, 187, 187);
    border-radius: 5px;
    padding: .7rem 0.5rem;
    padding-right: 2.5rem;
    width: 100%;
    background-color: transparent;
    font-family: var(--font);
    resize: vertical;
    min-height: 2rem;
    margin-bottom: 1.5rem;

    &::placeholder{
      font-family: var(--font);
      color: rgb(199, 199, 199);
    }
  }


  .login-side {
    width: 58%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .back{
      object-fit: cover;
      height: 100%;
      width: 100%;
      position: absolute;
    }

    .brand{
      color: black;
      height: 2.6rem;
      font-family: var(--font-secondary);
      font-size: 28px;
      margin-bottom: 2rem;
    }

    input {
      margin-top: .3rem;
      width: 100%;
      border-radius: 13px;
      padding: 1rem;
    }

    span{
      font-size: 12px;
      font-weight: bold;
    }

    .logo-vikings{
      width: 81px;
      height: 82px;
    }

    .forgot{
      text-align: end;
      font-size: 14px;
      margin-top: 0;
      margin-bottom: 3rem;
      text-decoration: underline
    }

    .text-gradient{
      font-size: 14px;
      text-align: center;
      margin-left: 0rem !important;
    }

    .btn-gral {
      width: 60%;
      text-transform: uppercase;
      justify-content: center;
      margin-left: 0rem !important;
    }
}

}


@media(max-width: 992px) {
  .login-page{
    display: flex;
    flex-direction: column;
    margin-bottom: 0rem !important;
  }

.login-page .gradient-side {
  height: 20rem !important;
  width: 100%;

  h4 {
    margin: -6rem 0rem 0rem 2rem;
  }
  p {
    width: 100%;
    margin-left: 2.2rem;
    font-size: 14px;
  }
}

 .login-page .login-side {
  width: 100%;
  padding-top: 2rem;
  justify-content: center;

  .btn-gral {
    width: 80%;
  }
}
}

@media(max-width: 377px) {
  .login-page{
    display: flex;
    flex-direction: column;
    margin-bottom: 6rem !important;
  }

  .login-page .gradient-side {
  
    h4 {
      margin: -6rem 0rem 0rem 3rem;
    }
    p {
      margin-left: 3.2rem;
    }
  }
}






