.box-grid-size {
    background-color: var(--greyColor);
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    padding: 23px 30px 19px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    text-decoration: none;
  
    &::before {
      background-image: linear-gradient(
        90deg,
        rgba(157, 43, 230, 0.5) 20.56%,
        rgba(233, 46, 178, 0.5) 51.01%,
        rgba(255, 198, 52, 7.5) 122.45%
      );
      position: absolute;
      top: -3px;
      bottom: -3px;
      left: -3px;
      right: -3px;
      z-index: -1;
      content: "";
      border-radius: 10px;
      opacity: 0;
      transition: cubic-bezier(0.1, 0, 0.2, 1) 0.5s;
    }
  
    &:hover {
      box-shadow: 5px 5px 20px rgba(87, 10, 99, 0.15);
      &::before {
        opacity: 1;
        transition: cubic-bezier(0.1, 0, 0.2, 1) 0.5s;
      }
      img {
        opacity: 1;
        transition: cubic-bezier(0.1, 0, 0.2, 1) 0.6s;
      }
    }
  
    p {
      margin: 5px 0 0 0;
      color: var(--darkFont);
      font-family: var(--font-secondary);
      font-weight: 400;
      font-size: 32px;
      text-align: left;
    }
    .description-size {
      font-family: var(--font-primary);
      font-weight: 200;
      font-size: 13px;
    }
  }
  
  @media (max-width: 992px) {
    .box-grid-size {
      padding: 12% 17%;

      p{
        font-size: 24px;
        line-height: 1;
      }
      .description-size {
        font-size: 11px;
      }
    }
  }

  @media (max-width: 800px) {
    .box-grid-size {
      padding: 10% 14%;

      p{
        font-weight: 200;
        font-size: 20px;
      }
    }
  }