.user-profile-container{
  margin-inline: 2rem;
    h2 {
        font-family: var(--font-secondary) !important;
        font-style: normal;
        font-weight: 400;
        font-size: 35px;
        line-height: 123.46%;
        text-align: center;
    }

    h3 {
        font-family: var(--font-secondary) !important;
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 46px;
    }
    &.btn-gral {
      margin: 1.5rem;
    }


    .user-profile-forms-container{
        display: flex;
        flex-direction: column;
        background-color: var(--background-personal-info);
        padding: 2.5rem;
        margin-bottom: 1rem;
        border-radius: 5px;

        .span-input-container-user-profile {
            display: flex;
            flex-direction: column;
            margin-bottom: 1rem;
            span {
                font-family: var(--font-primary);
                color: black;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 30px;
                text-transform: uppercase;
            }
        }
        .input, input {
            padding: 1rem 1rem 2rem;
            border: 1px solid #C4C4C4;
            box-sizing: border-box;
            border-radius: 10px;
            height: 2.5rem;
            align-items: baseline;
        }
    }

    .user-profile-password-container, .user-profile-orders-container{
        display: flex;
        flex-direction: row;
        background-color: var(--background-personal-info);
        justify-content: space-between;
        padding: 2.5rem;
        margin-bottom: 1rem;
        border-radius: 5px;

        .span-input-container-user-profile {
            display: flex;
            flex-direction: column;
            margin-bottom: 1rem;
            span {
                font-family: var(--font-primary);
                color: black;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 30px;
                text-transform: uppercase;
            }
        }
        input {
            padding: 1rem;
            border: 1px solid #C4C4C4;
            box-sizing: border-box;
            border-radius: 10px;
            width: 329.12px;
            height: 42.17px;
            left: 198px;
            top: 923.83px;
        }
    }
}

.span-container-user-profile {
    span{
        font-family: var(--font-primary);
        font-style: normal;
        font-weight: 550;
        font-size: 16px;
        line-height: 30px;
        text-transform: uppercase;
    }
    a{
      &.btn-gral {
        margin-bottom: 1.5rem;
        width: 30%;
      }
    }
}


.user-orders-container{
    margin-inline: 2rem;
    h2 {
        font-family: var(--font-secondary) !important;
        font-style: normal;
        font-weight: 400;
        font-size: 35px;
        line-height: 123.46%;
        text-align: center;
    }

    h3 {
        font-family: var(--font-secondary) !important;
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 46px;
    }

    .user-profile-orders-container{
        display: flex;
        flex-direction: column;
        background-color: var(--background-personal-info);
        margin-bottom: 1rem;
        border-radius: 5px;
    }

    .user-profile-orders-container{
        display: flex;
        background-color: var(--background-personal-info);
        justify-content: space-between;
        padding: 2.5rem;
        margin-bottom: 5rem;
        border-radius: 5px;
    }
}

.orders{
  display: flex;
  flex-direction: column;

  p{
    font-weight: bold;
    font-size:1.2rem;
  }

  div{
    width: 30%;
    display: flex;
    justify-content: space-between;
    padding-block:.5rem;
  }
}

.order-divider{
  width: 100%;
  margin-top: 5rem;
}

.table-container {
    margin-top: 3rem;
    box-sizing: border-box;
    border-radius: 7px;
    background: var(--background-element);
    color: var(--background-element);

    .scrolleable {
      table {
        width: 100%;
        border-collapse: collapse;

        thead {
            border-bottom: 1px solid rgb(163, 163, 163);
          tr {
            th {
              border-bottom: var(--border);
              text-align: left;
              font-weight: 500;
              padding: 0.5rem 1rem;
              white-space: nowrap;
              color: var(--color);

              &.small {
                width: 2rem;
              }
            }
          }
        }

        tbody {
          tr {
            &:nth-child(odd) {
              td {
                background-color: rgb(255 255 255 / 3%);
              }
            }

            td {
              color: var(--color);
              padding: 0.8rem 1rem;
              font-size: 0.9rem;

              img {
                width: 1.2rem;
                height: 1.2rem;
                cursor: pointer;
                display: block;
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 992px) {

    .go-details-btn{
      font-size: .8rem;
    }

    .user-profile-container{
      margin: 6rem 2rem;
    }
    h3{
      text-align: center;
      font-size: 1.2rem !important;
      margin: 0rem 0rem .5rem .5rem;
    }
    .user-profile-password-container{
      flex-direction: column !important;
      align-items: center;
    }
    .span-input-container-user-profile{
      input{
        width: 300px !important;
      }

    }
    .span-container-user-profile{
      .btn-gral {
        width: 90% !important;
      }
    }

    .orders{
      div{
        width: 100%;
      }
    }
  }
