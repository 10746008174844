.title {
  margin: 0;
}
.content-gral-banner {
  margin: 5rem 60px;
  h4 {
    font-size: 24px;
    font-family: var(--font-secondary);
    margin: 2rem;
    text-align: center;
  }

  .text-container {
    margin-left: 15rem;
    margin-right: 15rem;
    margin-bottom: 4rem;
  }
  .text-container p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-align: center;
    margin: 0;
  }

  .img-container {
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    margin: 2rem 0;

    .small-img2 {
      margin: 1rem;
      flex-wrap: nowrap;
      height: 19rem;
      width: 30%;
    }

    .small-img {
      margin: 1rem;
      flex-wrap: nowrap;
      height: 19rem;
      width: 30%;
    }

    .big-img {
      align-content: center;
      flex-wrap: nowrap;
      height: 22rem;
      max-width: 100%;
      text-align: center;
      width: 35%;
    }

    .small-img2 img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .big-img img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .small-img img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media (max-width: 992px) {
  .content-gral-banner {
    margin: 2rem auto;
    width: 90%;
    h2 {
      text-align: center;
    }

    .text-container {
      margin: 0;
    }

    .text-container p {
      margin: 1rem;
      font-size: 15px;
      text-align: center;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      overflow: hidden;
    }

    .img-container {
      margin: 0;
      flex-wrap: wrap;
      justify-content: space-between;

      .big-img {
        height: 20rem;
        order: 0;
        width: 100%;
      }

      .small-img2 {
        width: 48%;
        order: 1;
        height: auto;
        margin: 1rem 0;
        h3 {
          font-size: 1rem;
        }
      }

      .small-img {
        width: 48%;
        order: 1;
        height: auto;
        margin: 1rem 0;
      }
    }
  }
}
