.item-content-list {
  margin-top: 3rem;
  width: 90%;
  display: flex;
  flex-direction: column;
  .item-box-text {
    margin-bottom: 20px;
    h4 {
      color: #8928e9;
      margin-bottom: 0;
      font-size: 1.7rem;
    }

    ul {
      line-height: 1.5;
      padding-left: 18px;
      li {
        .down-text {
          text-align: left;
          margin: 0;
          font-size: 19px;
          color: var(--darkFont);
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .item-content-list {
    margin: 0 auto;
    text-align: left;

    .item-box-text ul li .down-text{
      font-size: 17px;
    }
  }
}

@media (max-width: 1100px) {
  .item-box-gallery{
    display: none;
    margin-top: 3rem;
    flex-direction: column;
    align-items: center;
  }
  
}

