
  .up-footer-gradient {
    background: var(--baseGradient);
    height: .5rem;
  }

  .up-footer-grey{
    background: rgb(219, 219, 219);
    height: 1.3rem;
  }

footer {
  background-color: var(--dark-color);
  overflow: auto;
  padding: 50px 60px 0;
  box-sizing: border-box;

  .content-footer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .links-footer {
    display: flex;
    a {
      margin-left: 50px;
      display: block;
      text-transform: capitalize;
    }
  }

  a {
    color: white;
    font-family: var(--font-secondary);
    text-decoration: none;

    &.brand-footer {
      img {
        width: 150px;
        height: auto;
      }
    }
  }

  .bottom-footer {
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 0;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
    p {
      margin-bottom: 0;
    }

    .aditional-info{
      width:40%;

      & > .address-info{
        display:flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    .social-links-footer {
      display: flex;
      align-items: center;
      a {
        display: block;
        margin-left: 12px;

        img{
          width: 22px;
          height: 22px;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  footer{
    padding: 35px 20px 0;

    a.brand-footer{
      margin: 30px;
    }
    .content-footer{
      flex-direction: column-reverse;
      align-items: flex-start;
    }
    .links-footer{
      flex-direction: column;
      a{
        margin-left: 40px;
        padding-bottom: 12px;
        box-sizing: border-box;
      }
    }
    .bottom-footer{
      margin: 1rem 0 2rem 18px;
      flex-direction: column-reverse;
      align-items: flex-start;

      .aditional-info{
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      p{
        padding-left: 0;
      }
    }
  }
}
