.item-content {
  margin: 60px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .box-item-content {
    width: 30%;
  }
}

@media (max-width: 1700px) {
  .item-content {
    margin: 80px auto;
  }
}

@media (max-width: 900px) {
  .item-content {
    margin: 15px auto;
    flex-direction: column;
    text-align: center;
    align-items: center;

    .box-item-content {
      width: 100%;
      margin-bottom: 2rem;
    }
  }
}
