.signup-page {
  display: flex;

  .gradient-side {
    background: linear-gradient(140deg,
        #05acdf 1%, #9d2be5 39%, #ea2fb2 50%, #ffc634 92%);
    color: white;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;

    .signup-box {
      width: 55%;

      &.error {
        span {
          position: relative;

          &::after {
            content: '*';
            font-weight: bold;
            color: var(--error);
            margin-left: 2px;
            line-height: 5px;
          }
        }

        input {
          border-color: var(--error);
        }
      }

      .brand {
        width: 100%;
        height: 1.7rem;
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;
        font-weight: 600;
        font-size: 2rem;

        img {
          height: 100%;
        }
      }

    }

    .signup-cont {
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 5px;
      }

      h4 {
        font-family: var(--font-secondary);
        font-style: normal;
        font-weight: 400;
        font-size: 1.7rem;
        margin-bottom: 2rem;
      }

      p {
        margin-bottom: 12vw;
      }
    }

    .link {
      color: blue
    }
  }

  .password-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    .span-input-password {
      width: 45%;
    }
  }

  .address-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    .span-input-address {
      width: 45%;
    }
  }

  input,
  select,
  textarea {
    border: 1px solid rgb(187, 187, 187);
    border-radius: 5px;
    padding-top: .7rem 0.5rem;
    padding-right: 2.5rem;
    width: 110%;
    background-color: transparent;
    font-family: var(--font);
    resize: vertical;
    min-height: 2rem;
    margin-bottom: 1.5rem;

    &::placeholder {
      font-family: var(--font);
      color: rgb(199, 199, 199);
    }
  }

  .line-forms {
    position: relative;
    bottom: 99px;
    left: 200px;

    padding-top: .1rem 2rem;

    width: 47%;
    background-color: transparent;
    font-family: var(--font);
    resize: vertical;
    min-height: 2rem;
    margin-bottom: 1.5rem;

  }

  .line-formss {

    padding-top: .1rem 2rem;

    width: 47%;
    background-color: transparent;
    font-family: var(--font);
    resize: vertical;
    min-height: 2rem;
    margin-bottom: 1.5rem;

  }

  .signup-side {
    width: 60%;
    height: 90%;
    padding-block: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    form{
      padding-block:2rem !important;
    }

    .back {
      object-fit: cover;
      height: 100%;
      width: 100%;
      position: absolute;
    }

    .brand {
      color: black;
      height: 2.6rem;
      font-family: var(--font-secondary);
      font-size: 28px;
      margin-bottom: 2rem;
    }

    input {
      margin-top: .3rem;
      width: 100%;
      border-radius: 13px;
      padding: 1rem;
    }

    span {
      font-size: 12px;
      font-weight: bold;
    }

    .logo-vikings {
      width: 81px;
      height: 82px;
    }

    .forgot {
      text-align: end;
      font-size: 14px;
      margin-top: 0;
      margin-bottom: 3rem;
      text-decoration: underline
    }

    .text-gradient {
      font-size: 14px;
      text-align: center;
      bottom: 100px;
    }

    .btn-gral {
      width: 60%;
      text-transform: uppercase;
      justify-content: center;
      bottom: 100px;
      margin-top: 1rem;
    }
  }

}


@media(max-width: 992px) {

  .signup-cont {
    height: 30vh !important;
    justify-content: flex-end !important;
  }

  .password-label{
    display:flex;
    align-items: flex-end;
    height:2.2rem;
  }

  .signup-page {
    display: flex;
    flex-direction: column;
    margin-bottom: 13rem;
  }

  .signup-page .gradient-side {
    height: 20rem !important;
    width: 100%;

    h4 {
      margin-top: -6rem;
    }

    p {
      width: 100%;
      font-size: 14px;
    }
  }

  .signup-page .signup-side {
    width: 100%;

    .btn-gral {
      width: 100%;
    }
  }

  .signup-page .signup-side input,
  select,
  textarea {
    width: 100%;
  }

  .signup-side{
    margin-top: 1rem !important;
  }

  .signup-page .signup-side .line-forms {
    position: relative;
    bottom: 99px;
    left: 173px;

    padding-top: .1rem 2rem;

    width: 50%;
    background-color: transparent;
    font-family: var(--font);
    resize: vertical;
    min-height: 2rem;
    margin-bottom: 1.5rem;

  }

  .signup-page .signup-side .line-formss {

    padding-top: .1rem 2rem;

    width: 50%;
    background-color: transparent;
    font-family: var(--font);
    resize: vertical;
    min-height: 2rem;
    margin-bottom: 1.5rem;

  }

}


@media(max-width: 376px) {

  .signup-page {
    display: flex;
    flex-direction: column;
    margin-bottom: 23rem;
  }

  h4,
  p {
    text-align: center;
  }

  .signup-page .gradient-side {
    height: 20rem !important;
    width: 100%;

    p {
      font-size: 13px !important;
    }
  }
}
