.swiper-container-with-arrows {
  display: flex;
  align-items: center;
  background: var(--sliderGradient);

  .left-btn,
  .right-btn {
      img {
          width: 70px;
          padding-left: 3px;
      }
  }

  .right-btn {
      left: initial;
      right: -2.3rem;

      img {
          transform: none;
      }
  }
}


.message-bubble {

  padding: 10px;
  margin: 10px;
  max-width: 80%;
  word-wrap: break-word;
}

.admin-message {
  background-color: #e6e6e6;
  align-self: flex-start;
  border-radius: 0px 10px 10px 10px
}

.user-message {
  background-color: #d1e7dd;
  align-self: flex-end;
  border-radius: 10px 0px 10px 10px
}
.comment-box {
  background-color: var(--greyColor);
    border-radius: 10px;
    text-align: start;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    height: 550px;
    width: 500px;
    align-content: center;
    justify-content: flex-start;
    overflow-y: scroll;



  span{
    color: #6d6d6d;
    font-weight: 500;
  }

  p {
    color: var(--darkFont);
    font-weight: 500;
    font-size: 15px;
    margin-top: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 0rem;
  }

  h2{
    margin-top: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 1rem;
  }

}


.new-message {
  width: 100%;
  position: absolute;
  bottom: 15px;
  display: flex;
  justify-content: space-evenly;
  margin-top: 2rem;

  .button-triangle {
    cursor: pointer;
    background-color: #8928e9;
    border-radius: 20px;
    width:10%;
    border-style: none;
    bottom: 35px;
    left: 417px;
    padding: 6px;
  }

  .msg{
    width: 70%;
    margin-left: 0.5rem;
    padding:0.5rem;
    overflow-y: scroll;
  }
}

.file-admin {
  width: 300px;
  border-radius: 30px;
  height: auto;
  margin-bottom: 1rem;
}

.title-approval{
  font-size: 27px;
  text-align: center;
  margin-top: 4rem;
}

.approval-page {
  display: flex;
    justify-content: space-between;
    align-items: center;

  .approval-side {
    color: rgb(0, 0, 0);
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 0;

    .login-box {
      width: 55%;

      &.error {
        span {
          position: relative;

          &::after {
            content: '*';
            font-weight: bold;
            color: var(--error);
            margin-left: 2px;
            line-height: 5px;
          }
        }

        input {
          border-color: var(--error);
        }
      }

    }

   button{
     margin-top: 3rem;
   }

    .login-cont{
      margin-top: 18rem;
      margin-right:10rem;

      p{
        font-size:14px;
        font-weight: 400;
        line-height: 5px;
      }

      h2{
        margin-bottom: 8rem;
      }
    }

  }


  .comment-side {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 5px 5px 20px rgba(10, 19, 99, 0.15);
    padding-bottom: 5rem;

    span{
      font-size: 12px;
      font-weight: bold;
    }

    .btn-gral {
      width: 60%;
      text-transform: uppercase;
      justify-content: center;
      margin-left: 4.5rem;
    }
}

.fileup{
  width: 50%;
  filter: invert(100%);
}


.file-upload-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.file-upload-button:hover {
  background-color: #0056b3;
}

.preview-image{
  width: 50%;
}

}


@media(max-width: 992px) {
  .approval-page{
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
  }

.approval-page .comment-side {

  width: 100%;
  }

 .approval-page .approval-side {
  width: 100%;
  padding-top: 2rem;
  }

  .approval-page .title-approval {
    position: relative;
    bottom:-20px;
    font-size: 30px;
    margin-bottom: 8rem;
  }

  .comment-box .button-triangle {
    background-color: #8928e9;
    border-radius: 20px;
    width: 0rem;
    border-style: none;
    position: relative;
    bottom: 0px !important;
    left: 0px !important;
    padding: 0px !important;

  }

}
