.content-email {
  width: 1000px;
  margin: 3rem auto 5rem auto;

  .title-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    h3 {
      margin: 0;
    }

    .btn-secondary {
      width: fit-content;
      padding: 0 27px;
      font-size: 13px;
      line-height: 47px;
    }
  }
}

.validated-mail-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 14% auto;

  .txt-empty {
    text-align: left;
    width: 45%;

    h3 {
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 46px;
      font-family: var(--font-secondary);
      color: #000000;
      margin: 0 0 14px;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 19px;
      line-height: 25px;
      margin-bottom: 4rem;
    }
  }
}



@media (max-width: 1100px) {
  .content-cart {
    width: 90%;
    margin: 0 auto 4.5rem;
    padding-top: 70px;
    box-sizing: border-box;

    .title-content {
      margin: 1rem 0 2rem 0;

      h3 {
        font-size: 30px;
        line-height: 123.46%;
        margin-top: 20px;
      }
      .btn-secondary {
        height: 37px;
        line-height: 36px;
        padding: 0 16px;
        font-size: 12px;
      }
    }

    .content-email-list {
      padding: 35px 30px;
    }
  }
}

@media (max-width: 992px) {
  .validated-mail-content {
    img {
      width: 80%;
    }
  }
  .content-email {
    .top-table {
      display: none;
    }

    .title-content {
      flex-direction: column;

      .btn-secondary {
        margin-top: 1rem;
      }
    }
  }
}

@media (max-width: 800px) {
  .validated-mail-content {
    margin: 2rem auto;
    flex-direction: column;
    text-align: center;

    img {
      width: 15rem;
    }

    .txt-empty {
      text-align: center;
      margin-top: 2rem;
      width: 90%;

      h3{
        line-height: 1.1;
      }

      p {
        margin-bottom: 3rem;
      }

      a{
        margin: 0 auto;
      }
    }
  }
}
