.home-container {
  overflow: hidden;

  a {
    text-decoration: none;
  }
}
.image-top-float-right{
  .img1{
    float: right;
  }
  .img2{
    float: left;
  }
}

.our-designs-title-button {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.image-buttom-float-left{
  img{
    float: left;
  }
}


.top-init-banner {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  .banner-side {
    width: 100%;
    margin-top: -.6rem;
    height: calc(100vh - 90px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../../assets/img/expl.png);
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);

    .are-u-ready-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h2, h3 {
        font-family: 'Dela Gothic One';
        font-style: normal;
        font-weight: 400;
        font-size: 60px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
      }

      h3 {
        font-size: 28px;
        margin-top: -3.4rem;
        text-transform: none;
        color: #FFFFFF;
      }

      .btn-gral {
        height: 4rem;
        display: flex;
        align-items: center;
      }
    }
  }
  .content-side-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .box-grid-init {
      width: 90%;
      margin-top: 2.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .content-button {
        margin: 40px 0 0;
        justify-content: center;
        display: flex;
      }
    }
  }
}

.home-follow-us-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 0rem 7rem 0rem;

    .home-follow-us-content {
      width: 90%;

      h2 {
        font-family: 'Dela Gothic One';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 35px;

        span {
            color: #8928E9;
        }
    }

    }
}

.grid-library-home-container{
  background-color: #f7f7f7;
  padding: 2rem 0rem 5rem 0rem;
  margin-top: 0rem;
}

.grid-titles {
  margin-bottom: -1.2rem;
  h2, h3 {
    font-size: 26px;
    text-align: start;
    font-family: 'Dela Gothic One';
    font-weight: 400;
  }
  
  p {
    font-size: 20px;
    font-weight: 400;
    margin-top: -1.5rem;
    margin-bottom: 3rem;
    color: #7C7C7C;
    font-style: normal;
    font-family: 'Dela Gothic One'
  }
}


.home-promo-banner {
  margin: 7rem 0rem;
}

.home-sponsors-box-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F7F7F7;
  padding: 4rem 0rem;

  h2 {
    font-size: 24px;
    text-align: center;
    font-family: 'Dela Gothic One';
    font-weight: 400;
  }

  .home-sponsors-box-content {
    width: 90%;
  }
}

.grid-library-home-container{
  display: flex;
  align-items: center;
  justify-content: center;
  .grid-library-home-content {
    width: 90%;
  }

  .btn-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 2rem 0rem 2rem 0rem;

    .btn-gral {
      height: 4rem;
    }
  }
}


.items-library-texts{
  h4 {
    width: 100%;
    text-align: start;
    font-family: var(--font-secondary);
    font-weight: normal;
    font-size: 1.5rem;
  }
  
  h3 {
    width: 100%;
    text-align: start;
    font-family: var(--font-secondary);
    margin-bottom: 50px;
    font-weight: normal;
    color: grey;
    font-size: 18px;
  }


.item-library-subtitle{
  margin-top: -2.5rem;
  color: grey;  
}
}

.img-line-gradient{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: -2rem 0rem 4rem 0rem;
  img {
    width: 95%;
    margin-top: 7rem;
  }
}

.img-line-gradient-thick{
  padding: .5rem 0rem .5rem 0rem;
  background: var(--bigGradient);
  height: 5px;
  margin: 3rem 0rem 3rem 0rem;
}

.img-line-gradient{
  padding: .2rem 0rem .2rem 0rem;
  margin-top: 5rem;
  background: var(--bigGradient);
  height: 1px;
}

.line-left-middle {
  display: flex;
  justify-content: flex-start;
  margin-left: -5rem;
  width: 80%;
  height: .3rem;
  margin: 4rem 0;
  img {
    width: 85%;
  }
}

.line-rigth-middle {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: .3rem;
  margin: 4rem 0;
  img{
    width: 65%;
  }
}

@media (max-width: 1450px) {
  .home-follow-us-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 0rem 7rem 0rem;

  }

  .top-init-banner {
    .banner-side {
      height: 90vh;
    }
  }
}

@media (max-width: 1280px) {
  .img-line-gradient{
    margin: -3rem 0rem 4rem 0rem;
  }
  .top-init-banner .content-side-grid {
    h2 {
      margin: 30px 0;
    }
    .box-grid-init {
      transform: none;
    }
  }
}

@media (max-width: 1100px) {
  .image-top-float-right{
    img{
      display: none;
    }
  }
  .image-buttom-float-left{
    img{
      display: none;
    }
  }
  .top-init-banner {
    padding: 70px 0 0;
    margin-left: 0rem;
    box-sizing: border-box;
  }
}


@media (max-width: 1000px) {
  .top-init-banner {
    flex-direction: column;
    margin-left: 0rem;
    .content-side-grid {
      width: 100%;
      margin: 2rem auto;

      h2 {
        text-align: start;
      }

      .box-grid-init {
        width: 80%;
      }
    }
  }

  .our-designs-title-button {
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn-container {
      margin-top: 0rem;
      .btn-gral {
        height: 3rem;
      }
    }
  }

  .top-init-banner .banner-side {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .top-init-banner .content-side-grid .box-grid-init {
    width: 90%;
    margin-left: 0rem;
  }
}


@media (max-width: 600px) {
  .top-init-banner {
    .banner-side {

      .are-u-ready-section {
        h2, h3{
          font-size: 40px;
          margin: 0;
        }
      }
      .btn-gral {
        margin-top: 2rem;
        height: 3rem !important;
      }
    }
    .content-side-grid {
      .box-grid-init {
        .grid-titles {
          h2, p {
            text-align: center;
          }
          p {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
  .our-designs-title-button {
    h2, p {
      text-align: center;
    }
    p {
      font-size: 1.2rem;
      margin-top: .2rem;
    }
  }
  .home-follow-us-container {
      .home-follow-us-content {
        text-align: center;
      }
  }

}