.content-grid-big{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 27px;
}


@media (max-width:1100px) {
  .content-grid-big{
    grid-gap: 13px;
    grid-template-columns: repeat(2, 1fr);
  }
}
