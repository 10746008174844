.galleryCont {
  margin-top: 0;
  display: flex;
  gap: 2rem;
  justify-content: space-between;

  &.row {
    flex-direction: row;
  }

  &.grid-2x2 {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.galImg {
  width: 30%;
  box-shadow: 5px 5px 20px rgba(87, 10, 99, 0.3);
  border-radius: 10px;
  box-sizing: border-box;
}

