.box-item-library {
  background-color: white;
  border-radius: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  justify-content: center;
  height: 300px;

  img {
    width: 220px;
    border-radius: 10px;
    height: auto;
  }

  &::before {
    background-image: linear-gradient(
      90deg,
      rgba(157, 43, 230, 0.5) 20.56%,
      rgba(233, 46, 178, 0.5) 51.01%,
      rgba(255, 198, 52, 7.5) 122.45%
    );
    position: absolute;
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
    z-index: -1;
    content: "";
    border-radius: 10px;
    opacity: 0;
    transition: cubic-bezier(0.1, 0, 0.2, 1) 0.5s;
  }

  &:hover {
    box-shadow: 5px 5px 20px rgba(87, 10, 99, 0.15);
    &::before {
      opacity: 1;
      transition: cubic-bezier(0.1, 0, 0.2, 1) 0.5s;
    }
    button{
      opacity: 1;
    }
  }

  button {
    position: absolute;
    bottom: -24px;
    padding: 0;
    width: 80%;
  }

  p {
    color: var(--darkFont);
    font-weight: 500;
    text-transform: uppercase;
    font-size: 15px;
  }
}
