.content-grid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .box-grid-padding{
      padding: 30px 130px 40px;

      img {
        width: 150px;
        height: 150px;
      }
    }
  }
  
  .box-center {
    margin: auto;
    text-align: center;
  }

  @media (max-width: 1700px) {
    .content-grid{
      display: flex;
      flex-direction: row;
      justify-content: space-between;

    .box-grid-padding{
      padding: 40px 100px 40px;

      img {
        width: 130px;
        height: 130px;
      }
    }
    }
  }

  @media (max-width: 1450px) {
    .content-grid{

    .box-grid-padding{
      padding: 40px 80px 40px;

    }
    }
  }
  
  @media (max-width:1100px) {
    .content-grid{
      display: grid;
    grid-template-columns: 1fr 1fr;
      grid-gap: 13px;

      .box-grid-padding {
        padding: 10% 14%;
        
      }
    }
  }
  
  @media (max-width: 650px) {
    .content-grid{
      .box-grid-padding {
        img {
          width: 80px;
          height: 80px;
        }
      }
    }
  }