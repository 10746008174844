.content-grid-payment {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;

  .box-separate {
    margin-bottom: 1.3rem;
  }

  .left-side {
    width: 50%;
  }


  .shippingprice{
    display: flex;
    width: 80%;
    justify-content: space-between;


  }
  .shippingprice-total {
    display: flex;
    width: 80%;
    justify-content: space-between;
    position: relative;
    padding-bottom: 2px;
}

.shippingprice-total::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, #9D2BE5 0%, #EA2FB2 48%, #FFCD39 100%);
}


  .right-side {
    width: 40%;
  }
  .box-btns {
    display: flex;
    align-items: center;
    flex-direction: column;

    .btn-gral {
      padding: 0;
      margin-top: 40px;
      width: 100%;
    }
    .btn-clean-cart {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: none;
      margin-top: 0.9rem;
      text-decoration: underline;
      color: var(--purple);
      font-weight: 600;
      font-size: 14px;
      text-transform: uppercase;
      font-family: var(--font-primary);
      cursor: pointer;
    }
  }
}

@media (max-width: 800px) {
  .content-grid-payment {
    margin-top: 1rem;
    box-sizing: border-box;
    flex-direction: column-reverse;
    position: relative;
    padding-bottom: 6rem;

    .left-side,
    .right-side {
      width: 100%;
    }

    .btn-gral {
      position: absolute;
      bottom: 40px;
      width: 100%;
    }
    .btn-clean-cart {
      bottom: -20px;
      margin-left: 0;
    }
  }
}
