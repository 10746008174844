.box-general {
  background-color: white;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1.5px solid transparent;
  border-image-slice: 1;
  border-image-source: var(--baseGradient);
  margin-bottom: 2rem;

  .general-total-box {
    display: flex;
    margin-top: 15px;
    width: 100%;
    flex-direction: column;

    .input-coupon {
      min-height: 40px;
      line-height: 40px;
      padding: 0 10px;
      border-radius: 10px;
      margin-bottom: 2rem;
    }

    .btn-coupon {
      width: 100%;
      padding: 0;
    }
  }

  .coupon-info {
    font-size: 10px;
    display: flex;
    justify-content: flex-start;
    margin-top: 5px;
  }
}

.shippingcont {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.coupon-discount {
  color: #9d2be5;
}

.total-amount-text {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
}

.price-cont {
  margin: 0;
}

@media (max-width: 800px) {
  .total-amount-text {
    font-size: 20px;
  }
}
