.content-select {
  background-color: var(--greyColor);
  box-shadow: 5px 5px 20px rgba(87, 10, 99, 0.15);
  border-radius: 10px;
  padding: 2rem 2rem 4rem;
  box-sizing: border-box;
  position: relative;

  .btn-gral {
    margin-top: 3rem;
    width: 100%;
    padding: 0;
  }
}
.error-message {
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.input-quantity{
  opacity: 1;
    position: relative;
    width: 25%;
    height: 35px;
    padding-left: 5px;
    font-family: var(--font-primary);
    margin-top: 5px;
}

.error-proportion {
  font-size: 12px;
  color: red;
}

.popover-container-left {
  background-color: rgb(249, 56, 56);
  color: white;
  border-radius: 10px;
  margin: .2rem 3rem 0rem 0rem;
  padding: .8rem;
}

.popover-container-rigth {
  background-color: rgb(249, 56, 56);
  color: white;
  border-radius: 10px;
  margin: .2rem 0rem 0rem 3rem;
  padding: .8rem;
}
