.top-pay-banner {
  width: 100%;
  background: var(--bigGradient);
  height:55vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

hr{
  width: 85%;
}


.approval-side {
  color: rgb(0, 0, 0);
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 0;

  .login-box {
    width: 55%;

    &.error {
      span {
        position: relative;

        &::after {
          content: '*';
          font-weight: bold;
          color: var(--error);
          margin-left: 2px;
          line-height: 5px;
        }
      }

      input {
        border-color: var(--error);
      }
    }

  }

 button{
   margin-top: 3rem;
 }

  .login-cont{
    margin-top: 18rem;
    margin-right:10rem;

    p{
      font-size:14px;
      font-weight: 400;
      line-height: 5px;
    }

    h2{
      margin-bottom: 8rem;
    }
  }

}

.resume-order-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.center-text-banner {
  margin-top: -70px;
}

.total-amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7% 36px 7% 0;
  box-sizing: border-box;
  h5 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    margin: 0;
  }
  h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: var(--purple);
    margin: 0;
  }
}

.info-order {
  display: flex;
  gap: .8rem;
}

@media (max-width:1100px) {
  .content-cart.cart-view{
    margin: 3rem auto 5rem auto;
  }
}

@media (max-width: 800px) {
  .top-pay-banner {
    padding: 90px 0 0;
    box-sizing: border-box;
  }
  .center-text-banner {
    width: 90%;
    h2 {
      font-size: 29px;
    }
    p{
      font-size: 14px;
      line-height: 19px;
    }
  }
  .resume-order {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 33px 2rem;

    p {
      margin-bottom: 2vh;
    }
  }
  .total-amount {
    padding: 7% 0 15px 0;
  }
}
