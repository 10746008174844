.box-item-content {
  display: flex;
  flex-direction: column;
  text-align: center;
  .icon-item {
    width: 90px;
    height: 90px;
    margin: 0 auto;
    text-align: center;

    img {
      width: 100%;
    }
  }

  h5 {
    color: var(--darkFont) !important;
    font-family: var(--font-secondary);
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 29px;
    text-align: center !important;
    margin: 1rem 0;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.6;
    width: 89%;
    text-align: center !important;
    color: #000000;
    font-family: var(--font-primary);
    margin: 0 auto;
  }
  a {
    color: var(--purple);
    font-weight: 700;
    margin-top: 25px;
    display: block;
  }
}
