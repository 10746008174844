.follow-us-container {
    display: flex;
    flex-direction: column;

    .follow-us-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        

        img {
            width: 400px;
            height: auto;
        }
    }
}

@media (max-width: 1700px) {
    .follow-us-container {
        .follow-us-content {
            img {
                width: 350px;
                height: 340px;
            }
        }
    }
}

@media (max-width: 1450px) {
    .follow-us-container {
        .follow-us-content {
            img {
                width: 300px;
                height: 320px;
            }
        }
    }
}

@media (max-width: 1000px) {
    .follow-us-container {
        .follow-us-content {
            flex-wrap: wrap;
            justify-content: center;
            img {
                margin: 1rem;
                width: 300px;
                height: 320px;
            }
        }
    }
}