
header {
  background-color: var(--dark-color);
  color: white;
  display: flex;
  width: 100%;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  padding: 0 52px;
  box-sizing: border-box;
  font-family: var(--font-secondary);
  z-index: 500;

  .overlay-menu {
    display: none;
  }
  .content-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100px;

    .menu {
      display: none;
    }

    h1 {
      margin: 0;
      .brand {
        overflow: hidden;
        display: block;
        img {
          width: 400px;
          height: auto;
          vertical-align: bottom;
          padding-top: 9.5rem;
        }
      }
      .secondBrand{
        overflow: hidden;
        display: block;
        img {
          width: 300px;
          height: auto;
          vertical-align: bottom;
          padding-top: 7.5rem;
        }
      }
    }
    nav {
      flex-direction: row-reverse;
      display: flex;
      align-items: center;

      .cart {
        order: -1;
      }
      .nav-center{
        display: flex;
        flex-direction: row;
      }
      .cart-separator{
        display: none;
      }
      a {
        text-transform: capitalize;
        margin-inline: 1.5rem;
        text-align: center;
        font-size: 14px;
        display: block;
        color: white;
        text-decoration: none;
        position: relative;

        .countCart {
          background: #eb3f35;
          border-radius: 50%;
          position: absolute;
          min-width: 30px;
          height: 30px;
          text-align: center;
          font-size: 11px;
          font-family: var(--font-primary);
          font-weight: 600;
          top: -8px;
          left: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;

          &::after {
            content: none;
          }
        }

        &::after {
          content: "";
          height: 3px;
          width: 100%;
          display: block;
          position: absolute;
          display: block;
          bottom: -8px;
        }

        &.activeGreen {
          &::after {
            background-color: var(--green);
          }
        }

        &.activeLemon {
          &::after {
            background-color: var(--lemon);
          }
        }

        &.activeYellow {
          &::after {
            background-color: var(--yellow);
          }
        }
        &.activeRed {
          &::after {
            background-color: var(--red);
          }
        }
        &.activeCbd {
          &::after {
            background-color: var(--cbd);
          }
        }
        &.activePurple {
          &::after {
            background-color: var(--purple);
          }
        }

        &.cart {
          margin: 0px 40px 0px 50px;
          img {
            vertical-align: bottom;
            width: 20px;
            height: 21px;
          }

          span{
            display: none;
          }
        }
      }
    }
  }
}

.row {
  display: flex !important;
  flex-direction: row;
}

.nav-items{
  list-style: none;
}
.user-button {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;

  &:hover{
    filter: brightness(1.2);
  }
}

.dropdown {
  position: absolute;
  top: 100px;
  width: 200px;
  transform: translateX(-45%);
  overflow: hidden;
}

.user-button{
  font-family: var(--font-secondary);
  font-size: .9rem;
  text-transform: capitalize;
  color: white;
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-block: 1rem;
}

.menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background-color: var(--dark-color);
  a{
    color: black;
  }
}

.link:hover {
  color: rgb(136, 136, 136);
}


@media (max-width: 1280px) {
  header .content-header nav a {
    margin-left: 32px;
    text-align: center;

    &.cart {
      margin-left: 45px !important;
    }
  }
}

@media (max-width: 1100px) {

  .dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transform: translateY(200%);
    overflow: hidden;
    background-color: #1c0944;
    border: 1px solid rgb(234, 0, 255);
    border-radius: 1rem;
    margin-top: .5rem;
    animation: dropDownFadeIn .25s ease forwards;

    a{
      margin:0;
      background-color: #1c0944;
      line-height: 1 !important;
      font-size: 1rem !important;
      animation: fadeIn .25s ease forwards;
    }
  }

  .user-button{
    font-family: var(--font-secondary);
    font-size: .9rem;
    text-transform: capitalize;
    color: white;
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin-bottom: 2rem;
  }


  .cart-separator{
    display: block !important;
    background-color: #460fbe;
    padding:.015rem;
    border:none;
  }



  header {
    padding: 0 16px;
    height: 70px;
    position: fixed;
    top: 0;
    z-index: 3;


    .overlay-menu {
      display: none;
      position: fixed;
      z-index: 2;
      background-color: rgba(84, 84, 84, 62%);
      width: 100%;
      height: calc(100vh - 70px);
      left: 0;
      bottom: 0;
      transition: all 0.7s linear;

      &.active {
        display: block;
        transition: all 0.7s linear;
      }
    }

    .row {
      display: flex !important;
      flex-direction: column;

      a{
        padding-block: .75rem;
      }
    }
    .content-header {
      height: 70px;
      h1 {
        .brand, .secondBrand {
          margin-top: 20px;

          img {
            width: 150px;
            padding-top: 0rem;
            padding-right: 1rem;
          }
        }

      }
      .menu {
        display: block;
        width: 50px;
        appearance: none;
        border: none;
        background: transparent;
        padding: 0;

        img {
          width: 100%;
        }
      }
      nav {
        position: fixed;
        z-index: 3;
        flex-direction: column-reverse;
        bottom: 0;
        height: calc(100vh - 70px);
        right: -80%;
        justify-content: space-evenly;
        background: #0d0224;
        width: 80%;
        padding: 4% 0;
        box-sizing: border-box;
        transition: all 0.2s linear;
        .cart {
          order: 6;
        }
        .cart-separator{
          order:6;
        }

        div{
          li{
            display: flex;
            justify-content: center;
            .user-button{
              font-size: 1.3rem;
              text-align: center;
            }
          }
        }

        .nav-center{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        &.active {
          right: 0;
          transition: all 0.2s linear;
        }

        a {
          display:flex;
          align-items: center;
          justify-content: flex-start;
          padding: 0rem 3rem;
          width: 100%;
          text-align: center;
          height: calc(100% / 9);
          line-height: 3.3;
          font-size: 20px;

          &.cart {
            margin-left: 0;
            display:flex;
            justify-content: flex-start;

            span{
              display:block;
            }

            .countCart {
              top: 16px;
              left: 53%;
            }

            img, a{
              margin-inline:1rem;
            }

            img {
              vertical-align: middle;
              height:40px;
              width: 40px;
            }
          }
        }
      }
    }
  }
}

@keyframes dropDownFadeIn {
  from{
    opacity: 0;
    transform:translateY(200%);
  }
  to{
    opacity: 1;
    transform: translateY(210%);
  }
}
