.content-cart {
  width: 1000px;
  margin: 3rem auto 5rem auto;

  .title-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    h3 {
      margin: 0;
    }

    .btn-secondary {
      width: fit-content;
      padding: 0 27px;
      font-size: 13px;
      line-height: 47px;
    }
  }
  .content-cart-list {
    background: #f7f7f7;
    box-shadow: 5px 5px 20px rgba(10, 19, 99, 0.15);
    border-radius: 10px;
    padding: 45px 63px 33px;
    box-sizing: border-box;
    min-height: calc(100vh - 366px);
    position: relative;

    .message-text {
      text-align: center;
    }
  }
}

.empty-cart-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 14% auto;

  .txt-empty {
    text-align: left;
    width: 45%;

    h3 {
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 46px;
      font-family: var(--font-secondary);
      color: #000000;
      margin: 0 0 14px;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 19px;
      line-height: 25px;
      margin-bottom: 4rem;
    }
  }
}

.top-table {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 17px;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    color: #000000;
    opacity: 0.7;
    margin: 0;
    width: 103px;
    text-align: center;
    white-space: nowrap;

    &:first-child {
      width: 35%;
      text-align: left;
    }
  }
}

@media (max-width: 1100px) {
  .content-cart {
    width: 90%;
    margin: 0 auto 4.5rem;
    padding-top: 70px;
    box-sizing: border-box;

    .title-content {
      margin: 1rem 0 2rem 0;

      h3 {
        font-size: 30px;
        line-height: 123.46%;
        margin-top: 20px;
      }
      .btn-secondary {
        height: 37px;
        line-height: 36px;
        padding: 0 16px;
        font-size: 12px;
      }
    }

    .content-cart-list {
      padding: 35px 30px;
    }
  }
}

@media (max-width: 992px) {
  .empty-cart-content {
    img {
      width: 80%;
    }
  }
  .content-cart {
    .top-table {
      display: none;
    }

    .title-content {
      flex-direction: column;

      .btn-secondary {
        margin-top: 1rem;
      }
    }
  }
}

@media (max-width: 800px) {
  .empty-cart-content {
    margin: 2rem auto;
    flex-direction: column;
    text-align: center;

    img {
      width: 15rem;
    }

    .txt-empty {
      text-align: center;
      margin-top: 2rem;
      width: 90%;

      h3{
        line-height: 1.1;
      }

      p {
        margin-bottom: 3rem;
      }

      a{
        margin: 0 auto;
      }
    }
  }
}
