.content-images {
  text-align: center;
  margin: 2rem auto;
  img {
    width: 79px;
    height: 68px;
    margin: 0 1rem;

  }
}

.description-success {
  width: 75%;
  margin: 3rem auto;
  text-align: center;
}

@media (max-width:440px) {
    .content-images {
        img {
            width: 69px;
            height: 58px;
            margin: 0;
        }
    }
}

@media (max-width:320px) {
    .content-images {
        img {
            width: 69px;
            height: 58px;
            margin: 0 0;
        }
    }
}
