.content-grid-size-big{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
  }
  
  @media (max-width:1100px) {
    .content-grid-size-big{
      grid-gap: 10px;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  }

  @media (max-width:800px) {
    .content-grid-size-big{
      grid-gap: 10px;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  